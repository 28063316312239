<template>
  <v-container class="tw-mb-10">
    <v-row>
      <v-col cols="12">
        <h2
          class="tw-text-left tw-font-figtree tw-text-22 tw-font-semibold tw-leading-30 tw-text-dark-green tw-mb-8"
        >
          Edit your post
        </h2>
      </v-col>
    </v-row>
    <validation-observer ref="observer" v-slot="{ invalid }">
      <v-form @submit.prevent="save">
        <v-row>
          <v-col cols="12">
            <validation-provider
              v-slot="{ errors }"
              name="activity.name"
              rules="required"
            >
              <j-text-field
                label="Name of Activity*"
                v-model="activity.name"
                :error-messages="errors"
                placeholder="Cooking class, concert, dinner, hike, etc..."
                background-color="#fafafa"
                :hide-details="errors.length === 0"
                custom-classes="align-left"
              />
            </validation-provider>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <validation-provider
              v-slot="{ errors }"
              name="Message"
              rules="required"
            >
              <j-textarea
                v-model="activity.text"
                placeholder="This looks like a fun thing to do! Is anyone interested..."
                background-color="#fafafa"
                label="Post Message*"
                sublabel="Tell the crew what you love about this activity."
                :error-messages="errors"
                :hide-details="errors.length === 0"
              />
            </validation-provider>
          </v-col>
        </v-row>
        <v-row>
          <j-chip-group
            label="Event Type*"
            :items="activityTypes"
            :value="activity.type"
            @input="activity.type = $event"
          />
        </v-row>
        <v-row>
          <v-col cols="12">
            <j-text-field
              label="Name of Place*"
              v-model="activity.nameOfPlace"
              placeholder="Where is the event located?"
              background-color="#fafafa"
              custom-classes="align-left"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <validation-provider
              v-slot="{ errors }"
              name="activity.linkUrl"
              rules="max:500"
            >
              <j-text-field
                label="Link"
                v-model="linkUrl"
                placeholder="Paste the link where your crew can find out more"
                background-color="#fafafa"
                :error-messages="errors"
                :hide-details="errors.length === 0"
                custom-classes="align-left"
              />
            </validation-provider>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <j-textarea
              v-model="activity.note"
              placeholder="Note any costs, things to bring, how to register, etc..."
              background-color="#fafafa"
              label="Note"
            />
          </v-col>
        </v-row>
        <span class="tw-hidden">{{ invalid }}</span>
      </v-form>
    </validation-observer>
  </v-container>
</template>
<script>
import { mapMutations } from "vuex";
import { ACTIVITY_TYPES } from "@/enums/activities.js";

export default {
  name: "EditActivity",
  data() {
    return {
      activity: {
        name: "",
        description: "",
        text: "",
        type: null,
        nameOfPlace: "",
        linkUrl: "",
        note: "",
        tripId: this.$route.params.id,
        authorId: null
      },
      activityTypes: ACTIVITY_TYPES,
      linkUrl: null,
      metadata: null,
      metadataPending: false
    };
  },
  watch: {
    enable: function (val) {
      if (val) {
        this.$store.commit("meta/SET_NAVIGATION_BUTTON", {
          isDisabled: false
        });
      } else {
        this.$store.commit("meta/SET_NAVIGATION_BUTTON", {
          isDisabled: true
        });
      }
    },
    linkUrl(val) {
      this.fetchMetadata(val);
      this.activity.linkUrl = val;
    }
  },
  computed: {
    enable() {
      //TODO: Add check for eventType
      return (
        this.activity.nameOfPlace.length > 0 &&
        this.activity.name.length > 0 &&
        this.activity.text.length > 0 &&
        this.activity.type
      );
    }
  },
  methods: {
    ...mapMutations(["meta/SET_NAVIGATION_BUTTON"]),
    setNavigationButton(options) {
      this["meta/SET_NAVIGATION_BUTTON"](options);
    },
    async fetchMetadata(url) {
      if (this.checkValidUrl(url)) {
        this.metadataPending = true;
        await this.$store
          .dispatch("metascraper/create", { url })
          .then((res) => {
            if (res) {
              this.metadata = res;
              this.metadataPending = false;
              if (this.metadata.description)
                this.activity.text =
                  this.activity.text.length > 0
                    ? this.activity.text
                    : this.metadata.description;
              if (this.metadata.title && this.metadata.title.length < 200)
                this.activity.name = this.metadata.title;
              if (this.metadata.url) this.activity.linkUrl = this.metadata.url;
              if (this.metadata.image)
                this.activity.imageUrl = this.metadata.image.url;
            }
          })
          .catch((err) => {
            this.metadataPending = false;
            console.log(err);
          });
      }
    },
    checkValidUrl(url) {
      try {
        new URL(url);
      } catch (_) {
        return false;
      }
      return true;
    },
    async save() {
      this.setNavigationButton({ text: "Saving" });
      try {
        await this.$store.dispatch("post-activity/patch", [
          this.$route.params.uuid,
          {
            name: this.activity.name,
            text: this.activity.text,
            type: this.activity.type,
            placeName: this.activity.placeName,
            linkUrl: this.activity.linkUrl,
            note: this.activity.note
          }
        ]);
        setTimeout(() => {
          this.setNavigationButton({ text: "Save" });
          this.$router.push({
            name: "ActivitiesRoute",
            params: { id: this.$route.params.id }
          });
        }, 20);
      } catch (error) {
        console.error(error);
      }
    }
  },
  async beforeMount() {
    const activity = await this.$store.dispatch("post-activity/find", {
      query: {
        uuid: this.$route.params.uuid
      }
    });
    this.activity = activity.data[0];
    this.linkUrl = this.activity.linkUrl;
    this.$store.commit("meta/setHeader", {
      defaultHeaderDisabled: false,
      pageTitle: "Things to do",
      bgColor: "#0036F5",
      fontColor: "#ffffff",
      tagline: null,
      iconOne: null,
      iconTwo: null,
      tripHubButton: true,
      tripHubButtonId: this.$route.params.id
    });
    this.setNavigationButton({
      button: true,
      text: "Save",
      isDisabled: false,
      method: this.save,
      bgColor: "tw-bg-chartreuse"
    });
  },
  destroyed() {
    this.$store.commit("meta/SET_NAVIGATION_BUTTON", {
      button: false
    });
  }
};
</script>
